import React, { useEffect, useState } from 'react'
import { useRecoilValue } from 'recoil'
import {
    Flex,
    Box,
    Collapse,
    Center,
    IconButton,
    useColorModeValue,
    VStack,
    SlideFade,
} from '@chakra-ui/react'
import { ZoomControl, RotationControl } from 'react-mapbox-gl'
import { MdOutlineExpandLess } from 'react-icons/md'
import { MdOutlineExpandMore } from 'react-icons/md'
import AltitudeSlider from '../sliders/altitude-slider'
import ImagerySwitcher from './components/ImagerySwitcher'
import AerodromeButton from './components/AerodromeButton'
import LegendButton from './components/LegendButton'
import RulerButton from './components/RulerButton'
import BasemapEnhanceButton from './components/BasemapEnhanceButton'
import {
    dataModeState,
    layerSelectionState,
    contrastState,
    basemapState,
} from '../../../globalState'

// See note below, reset button child elements in App.css
const mapboxStyleOveride = {
    position: 'relative',
    inset: 'auto',
    marginBottom: '0px',
    boxShadow: 'none',
    border: 'none',
}

export default function MapControls({
    expanded,
    setExpanded,
    altitudeSliderExpanded,
    setAltitudeSliderExpanded,
    sliderVisible,
    setSliderVisible,
    rulerMode,
    setRulerMode,
}) {
    const dataMode = useRecoilValue(dataModeState)
    const layerSelection = useRecoilValue(layerSelectionState)
    const contrastHandler = useRecoilValue(contrastState)
    const basemap = useRecoilValue(basemapState)
    const [show, setShow] = useState(false)

    // Chakra light and dark mode
    const collapseButtonHover = useColorModeValue('light.20', 'dark.301')
    const collapseButtonHigh = useColorModeValue('light.10', 'dark.303')
    const collapseButtonMedium = useColorModeValue('light.10', 'dark.302')
    const collapseButtonStandard = useColorModeValue('light.10', 'dark.300')
    const collapseButtonColor = useColorModeValue('brand.strong', 'dark.80')
    const folderItemColor = useColorModeValue('light.10', 'light.10')

    const showMapEnhanceButton = basemap === 'vnc' || basemap === 'linztopo'

    const noFocus = {
        _focus: { boxShadow: 'none' },
        _focusVisible: { boxShadow: 'none !important' },
    }

    useEffect(() => {
        if (
            dataMode === 'aero' ||
            (dataMode === 'met' &&
                (layerSelection.met.sigmet ||
                    layerSelection.met.sigwx ||
                    layerSelection.met.aaw)) ||
            dataMode === 'advs'
        ) {
            setSliderVisible(true)
        } else {
            setSliderVisible(false)
        }
    }, [dataMode, layerSelection, setSliderVisible])

    return (
        <>
            <Flex
                id={'mapControls'}
                direction={'column'}
                position={'absolute'}
                borderRadius={'20px'}
                justifyContent={'center'}
                w={{ base: '40px', md: '38px' }}
                top={'15px'}
                right={'15px'}
                bgColor={
                    contrastHandler === 'high'
                        ? 'rgba(20,25,30, 0.42)' // If High Contrast Layer
                        : contrastHandler === 'medium'
                        ? 'rgba(20,25,30, 0.28)' // If Medium Contrast Layer
                        : 'rgba(20,25,30, 0.15)' // All other layers
                }
                backdropFilter="blur(2px)"
                boxShadow="inset 0px 0px 15px -7px rgba(0, 0, 0, 0.1)"
            >
                <Center
                    w="100%"
                    h="40px"
                    borderRadius={'20px'}
                    boxShadow={useColorModeValue(
                        '0px 4px 12px -5px rgba(0, 0, 0, 0.3)',
                        '0px 2px 20px -8px rgba(255,255,255,0.3)'
                    )}
                >
                    <IconButton
                        w="40px"
                        h="40px"
                        minW="40px"
                        transform={expanded && 'rotate(180deg)'}
                        style={{ transition: 'transform 0.2s' }}
                        fontSize="1.6rem"
                        borderRadius={'20px'}
                        bgColor={
                            expanded
                                ? contrastHandler === 'high'
                                    ? collapseButtonHigh // If High Contrast Layer
                                    : contrastHandler === 'medium'
                                    ? collapseButtonMedium // If Medium Contrast Layer
                                    : collapseButtonStandard // All other layers
                                : contrastHandler === 'high'
                                ? collapseButtonHigh // If High Contrast Layer
                                : contrastHandler === 'medium'
                                ? collapseButtonMedium // If Medium Contrast Layer
                                : collapseButtonStandard // All other layers
                        }
                        color={collapseButtonColor}
                        _hover={{
                            backgroundColor:
                                contrastHandler === 'high'
                                    ? collapseButtonMedium // If High Contrast Layer
                                    : contrastHandler === 'medium'
                                    ? collapseButtonStandard // If Medium Contrast Layer
                                    : collapseButtonHover, // All other layers
                        }}
                        _active={{
                            backgroundColor:
                                contrastHandler === 'high'
                                    ? collapseButtonMedium // If High Contrast Layer
                                    : contrastHandler === 'medium'
                                    ? collapseButtonStandard // If Medium Contrast Layer
                                    : collapseButtonHover, // All other layers
                        }}
                        icon={<MdOutlineExpandMore />}
                        onClick={() => setExpanded(!expanded)}
                        {...noFocus}
                    />
                </Center>
                <Collapse
                    animateOpacity
                    in={expanded}
                    style={{
                        overflow: 'initial',
                    }}
                >
                    <Flex flexDir="column" alignItems="center" pt="14px">
                        <VStack
                            spacing={{
                                base: '0.85rem',
                                md: '0.85rem',
                                lg: '3',
                            }}
                            pb={!sliderVisible ? '15px' : '0'}
                        >
                            {/* <Flex
                                zIndex={!show ? '1' : '1401'}
                                flexDir="column"
                                alignItems="center"
                                position="absolute"
                            >
                                <ImagerySwitcher
                                    show={show}
                                    setShow={setShow}
                                />
                            </Flex> */}
                            <SlideFade
                                zIndex={show ? '1' : '2'}
                                offsetY="20px"
                                animateOpacity
                                in={!show}
                                style={{ overflow: 'initial' }}
                            >
                                <Flex
                                    pt={{
                                        base: '15px',
                                        md: '13px',
                                    }}
                                    marginTop="30px"
                                    flexDir="column"
                                    alignItems="center"
                                >
                                    <VStack
                                        spacing={{
                                            base: '0.85rem',
                                            md: '0.85rem',
                                            lg: '3',
                                        }}
                                        // pb={!sliderVisible ? '15px' : '0'}
                                    >
                                        <AerodromeButton />
                                        <LegendButton />
                                        <RulerButton
                                            rulerMode={rulerMode}
                                            setRulerMode={setRulerMode}
                                        />
                                    </VStack>
                                </Flex>
                            </SlideFade>

                            <SlideFade
                                zIndex={showMapEnhanceButton ? '1' : '2'}
                                animateOpacity
                                in={showMapEnhanceButton}
                                style={{
                                    overflow: 'initial',
                                    marginTop: '0px',
                                    transition: 'all ease 600ms',
                                }}
                            >
                                <Flex
                                    flexDir="column"
                                    alignItems="center"
                                    marginTop={
                                        showMapEnhanceButton ? '15px' : '0px'
                                    }
                                    height={
                                        showMapEnhanceButton ? '30px' : '0px'
                                    }
                                    opacity={showMapEnhanceButton ? '1' : '0'}
                                    transition="all ease 600ms"
                                >
                                    <BasemapEnhanceButton basemap={basemap} />
                                </Flex>
                            </SlideFade>

                            <ZoomControl
                                className="zoom-control"
                                style={{ ...mapboxStyleOveride }}
                            />

                            <RotationControl
                                className="rotation-tool"
                                style={{ ...mapboxStyleOveride }}
                            />
                        </VStack>
                        <Collapse
                            marginTop="0"
                            style={{
                                marginTop: '0px !important',
                                overflow: 'initial',
                            }}
                            in={altitudeSliderExpanded}
                        >
                            <Flex
                                w="100%"
                                marginTop={{
                                    base: '1.7rem',
                                    md: '1.7rem',
                                    lg: '1.6rem',
                                }}
                                marginBottom="3"
                                direction={'column'}
                                display={!sliderVisible && 'none'}
                            >
                                <AltitudeSlider mapToolsExpanded={expanded} />
                            </Flex>
                        </Collapse>
                    </Flex>
                    <Center
                        w="100%"
                        h="40px"
                        mt={altitudeSliderExpanded ? '10px' : '10px'}
                        display={!sliderVisible ? 'none' : 'flex'}
                    >
                        <IconButton
                            w="30px"
                            h="30px"
                            minW="30px"
                            transform={
                                ((sliderVisible && !altitudeSliderExpanded) ||
                                    !expanded) &&
                                'rotate(180deg)'
                            }
                            style={{ transition: 'transform 0.2s' }}
                            fontSize="1.5rem"
                            borderRadius={'20px'}
                            bgColor={{
                                base: 'rgba(255,255,255,0.2)',
                                md: 'rgba(255,255,255,0.2)',
                            }}
                            color={folderItemColor}
                            _hover={{
                                backgroundColor: 'rgba(255,255,255,0.3)',
                            }}
                            icon={<MdOutlineExpandLess />}
                            onClick={
                                sliderVisible
                                    ? () =>
                                          setAltitudeSliderExpanded(
                                              !altitudeSliderExpanded
                                          )
                                    : () => setExpanded(!expanded)
                            }
                            {...noFocus}
                        />
                    </Center>
                </Collapse>
            </Flex>

            <Flex
                opacity={expanded ? '1' : '0'}
                visibility={expanded ? 'visible' : 'hidden'}
                transition="all linear 200ms"
                zIndex={!show ? '1' : '1401'}
                flexDir="column"
                alignItems="flex-end"
                position="absolute"
                top="69px"
                right="19px"
            >
                <ImagerySwitcher show={show} setShow={setShow} />
            </Flex>

            <Box // Shaded background behind Slide Up and Slide Down Drawers.
                className="closeOverlay"
                background={show ? 'rgba(0,0,0,0.4)' : 'transparent'}
                transition="all ease-in 350ms"
                transitionDelay="0ms"
                height="100%"
                width="100%"
                visibility={show ? 'visible' : 'hidden'}
                display={{
                    base: 'flex',
                    md: 'flex',
                }}
                zIndex={show ? '1400' : '0'}
                position="fixed"
                top="0px"
                left="0"
                marginTop="0 !important"
                onClick={() => {
                    setShow(false)
                }}
            ></Box>
        </>
    )
}
