import React, { useEffect, useState, useRef } from 'react'
import { useRecoilValue, useRecoilState, useSetRecoilState } from 'recoil'
import {
    Text,
    Box,
    Flex,
    useColorModeValue,
    IconButton,
    useBreakpointValue,
} from '@chakra-ui/react'
import { parseISO, formatISO } from 'date-fns'
import { formatTimeDate, formatTimeAndDay } from '../../../util/dateFormatter'
import { isMobile, isMobileOnly, isIOS } from 'react-device-detect'
import { ReactComponent as InfoIcon } from '../../../icons/assets/info-nocircle.svg'
import {
    sigWxDataState,
    closeLayerSelectorHandler,
    timeZoneState,
    advisoriesMetaDataExpandState,
    advisoriesListExpandState,
    mapControlsExpandState,
    selectedTimeState,
} from '../../../globalState'

export default function AdvisoriesMetaData() {
    const closeLayerSelector = useSetRecoilState(closeLayerSelectorHandler)
    const data = useRecoilValue(sigWxDataState)
    const metaData = data && data.metadata
    const timeZone = useRecoilValue(timeZoneState)
    const selectedTime = useRecoilValue(selectedTimeState)
    const [expanded, setExpanded] = useRecoilState(
        advisoriesMetaDataExpandState
    )
    const [advisoriesListExpanded, setAdvisoriesListExpanded] = useRecoilState(
        advisoriesListExpandState
    )
    const [mapControlsExpand, setMapControlsExpand] = useRecoilState(
        mapControlsExpandState
    )
    const [metaDataRead, setMetaDataRead] = useState(false)
    const endShowTimer = useRef(null)
    const expandOnDefault = window.innerWidth >= 768
    const isIphone = isMobileOnly && isIOS

    const sigwxValidTo = metaData && formatISO(parseISO(metaData.validToTime))
    const formattedSelectedTime = selectedTime && formatISO(selectedTime)
    const selectedTimePastValidTo = formattedSelectedTime > sigwxValidTo

    useEffect(() => {
        if (expandOnDefault) {
            setExpanded(true)
        }
    }, [expandOnDefault, setExpanded])

    useEffect(() => {
        if (
            (advisoriesListExpanded && (isMobile || window.innerWidth < 768)) ||
            mapControlsExpand
        ) {
            setExpanded(false)
        }
        if (selectedTimePastValidTo) {
            setExpanded(false)
        }
    }, [
        advisoriesListExpanded,
        mapControlsExpand,
        selectedTimePastValidTo,
        setExpanded,
    ])

    useEffect(() => {
        if (expanded && (isMobile || window.innerWidth < 768)) {
            setAdvisoriesListExpanded(false)
        }
        if (expanded) {
            setMapControlsExpand(false)
        }
    }, [expanded, setAdvisoriesListExpanded, setMapControlsExpand])

    useEffect(() => {
        if (!metaDataRead && !isMobile && expandOnDefault) {
            setExpanded(true)
            endShowTimer.current = setTimeout(() => {
                setExpanded(false)
                setMetaDataRead(true)
            }, 13000)
        } else {
            clearTimeout(endShowTimer.current)
        }
    }, [metaDataRead, expandOnDefault, setExpanded, setMetaDataRead])

    const noFocus = {
        _focus: { boxShadow: 'none' },
        _focusVisible: { boxShadow: 'none !important' },
    }
    const focusShadow = {
        _focus: {
            boxShadow: useColorModeValue(
                '0px 1px 12px -5px rgba(0, 0, 0, 0.45)',
                '0px 1px 12px -5px rgba(0, 0, 0, 0.45)'
            ),
        },
    }
    const buttonShadowOpen = useColorModeValue(
        '0px 1px 12px -5px rgba(0, 0, 0, 0.5)',
        '0px 2px 12px -5px rgba(255,255,255,0.5)'
    )
    const topRightRadius = useBreakpointValue({
        base: '18px',
        md: '25px',
    })
    const bottomRightRadius = useBreakpointValue({
        base: '25px',
        md: '18px',
    })
    const buttonColor = useColorModeValue('light.100', 'dark.80')
    const expandButton = useColorModeValue('light.10', 'dark.302')
    const expandButtonHover = useColorModeValue('light.20', 'dark.303')
    const buttonPulse = useColorModeValue('rgba(255,255,255,0.5)', 'dark.306')

    const metaDataAlertsMinHeight = metaData && metaData.alerts ? 42 : 0
    const metaDataInfoMinHeight = metaData && metaData.information ? 94 : 0
    const metaDataNotesMinHeight = metaData && metaData.notes ? 52 : 0
    const metaDataTimesMinHeight = metaData && metaData.notes ? 80 : 0

    // useEffect(() => {
    //     console.log('data: ', data)
    //     console.log('metaData: ', metaData)
    // }, [metaData])

    return (
        <>
            <Flex
                className="list"
                direction={'row'}
                alignItems="center"
                justifyContent="space-between"
                position={{ base: 'fixed', md: 'fixed' }}
                bottom={{
                    base: isIphone ? '180px' : '165px',
                    md: '90px',
                }}
                right={{
                    base: '17px',
                    md: '18px',
                }}
                zIndex="1"
                pointerEvents={(!metaData || selectedTimePastValidTo) && 'none'}
            >
                {metaData && !selectedTimePastValidTo && (
                    <>
                        <Flex
                            pointerEvents="none"
                            flexDirection="column"
                            // background={
                            //     expanded
                            //         ? 'rgba(20,25,30, 0.22)'
                            //         : 'rgba(255,255,255,0)'
                            // }
                            // boxShadow={
                            //     expanded
                            //         ? 'inset 0px 0px 15px -7px rgba(0, 0, 0, 0.1)'
                            //         : '0px 5px 35px -7px rgba(0, 0, 0, 0)'
                            // }
                            overflow="hidden"
                            borderRadius="25px"
                            borderTopRightRadius={topRightRadius}
                            borderBottomRightRadius={bottomRightRadius}
                            backdropFilter="blur(10px)"
                            style={{ backdropFilter: 'blur(10px)' }}
                            width={{
                                base: expanded ? '280px' : '0px',
                                xs: expanded ? '300px' : '0px',
                                sm: expanded ? '330px' : '0px',
                            }}
                            minWidth={{
                                base: expanded ? '280px' : '0px',
                                xs: expanded ? '300px' : '0px',
                                sm: expanded ? '330px' : '0px',
                            }}
                            maxWidth={{
                                base: expanded ? '280px' : '0px',
                                xs: expanded ? '300px' : '0px',
                                sm: expanded ? '330px' : '0px',
                            }}
                            transition="all ease-in-out 300ms"
                            justifyContent="space-between"
                            px="0"
                            pb={
                                expanded &&
                                metaData &&
                                !metaData.validFromTime &&
                                !metaData.validToTime
                                    ? '45px'
                                    : '0px'
                            }
                            gap="5px"
                            position="absolute"
                            bottom="0px"
                            right={{
                                base: '1px',
                                md: '0px',
                            }}
                            zIndex="1"
                        >
                            <Flex
                                width="100%"
                                minHeight={
                                    expanded
                                        ? `${
                                              metaDataAlertsMinHeight +
                                              metaDataInfoMinHeight +
                                              metaDataNotesMinHeight +
                                              metaDataTimesMinHeight +
                                              85
                                          }px`
                                        : '0px'
                                }
                                background={
                                    expanded
                                        ? 'rgba(20,25,30, 0.22)'
                                        : 'rgba(255,255,255,0)'
                                }
                                boxShadow={
                                    expanded
                                        ? 'inset 0px 0px 15px -7px rgba(0, 0, 0, 0.1)'
                                        : '0px 5px 35px -7px rgba(0, 0, 0, 0)'
                                }
                                overflow="hidden"
                                borderRadius="25px"
                                // backdropFilter="blur(10px)"
                                // style={{ backdropFilter: 'blur(10px)' }}
                                opacity={expanded ? '1' : '0'}
                                visibility={expanded ? 'visible' : 'hidden'}
                                transition="all ease-in-out 300ms"
                                flexDirection="column"
                                justifyContent="space-between"
                                alignItems="center"
                            >
                                <Flex
                                    width="100%"
                                    minHeight={expanded ? '65px' : '0px'}
                                    borderRadius="15px"
                                    transition="all ease-in-out 300ms"
                                    flexDirection="column"
                                    justifyContent="center"
                                    alignItems="center"
                                    fontSize={{
                                        base: expanded ? '0.85rem' : '0.0rem',
                                        md: expanded ? '0.9rem' : '0.0rem',
                                    }}
                                    textShadow="1px 1px 3px rgba(0,0,0,0.15)"
                                >
                                    <Flex
                                        width="100%"
                                        borderBottom={
                                            metaData && metaData.information
                                                ? '1px solid'
                                                : '0px solid'
                                        }
                                        borderColor="rgba(255,255,255,0.3)"
                                        flexDirection="column"
                                        justifyContent="center"
                                        alignItems="center"
                                        transition="all ease-in-out 300ms"
                                        minHeight={expanded ? '40px' : '0px'}
                                    >
                                        <Text
                                            px="5px"
                                            pt={{ base: '1px', md: '0' }}
                                            width={{
                                                base: expanded
                                                    ? '250px'
                                                    : '0px',
                                                xs: expanded ? '270px' : '0px',
                                                sm: expanded ? '300px' : '0px',
                                            }}
                                            minWidth={{
                                                base: expanded
                                                    ? '250px'
                                                    : '0px',
                                                xs: expanded ? '270px' : '0px',
                                                sm: expanded ? '300px' : '0px',
                                            }}
                                            color="light.10"
                                            textAlign="center"
                                            whiteSpace="pre-wrap"
                                            fontWeight="600"
                                            letterSpacing="0.5px"
                                            transition="all ease-in-out 300ms"
                                            fontSize={{
                                                base: expanded
                                                    ? '0.95rem'
                                                    : '0rem',
                                                md: expanded
                                                    ? '1.1rem'
                                                    : '0rem',
                                            }}
                                            lineHeight="1.3"
                                            textTransform="uppercase"
                                        >
                                            Graphical NZ SIGWX
                                        </Text>
                                    </Flex>

                                    {metaData && metaData.validUpToFL && (
                                        <Flex
                                            px="5px"
                                            pt="5px"
                                            pb="7px"
                                            width={{
                                                base: expanded
                                                    ? '250px'
                                                    : '0px',
                                                xs: expanded ? '270px' : '0px',
                                                sm: expanded ? '300px' : '0px',
                                            }}
                                            minWidth={{
                                                base: expanded
                                                    ? '250px'
                                                    : '0px',
                                                xs: expanded ? '270px' : '0px',
                                                sm: expanded ? '300px' : '0px',
                                            }}
                                            minHeight={{
                                                base: expanded ? '38px' : '0px',
                                                sm: expanded ? '36px' : '0px',
                                            }}
                                            transition="all ease-in-out 300ms"
                                            flexDirection="row"
                                            justifyContent="center"
                                            alignItems="center"
                                            letterSpacing="0.5px"
                                            fontSize={{
                                                base: expanded
                                                    ? '0.85rem'
                                                    : '0rem',
                                                md: expanded
                                                    ? '0.9rem'
                                                    : '0rem',
                                            }}
                                        >
                                            <Text
                                                color="light.10"
                                                textAlign="left"
                                                whiteSpace="pre-wrap"
                                                fontWeight="600"
                                                lineHeight="1.3"
                                            >
                                                SFC –
                                            </Text>
                                            <Text
                                                color="light.10"
                                                textAlign="left"
                                                whiteSpace="pre-wrap"
                                                fontWeight="600"
                                                lineHeight="1.3"
                                                pl="5px"
                                            >
                                                FL{metaData.validUpToFL}
                                            </Text>
                                        </Flex>
                                    )}

                                    {/* CONTENT */}
                                    {metaData && metaData.alerts && (
                                        <Flex
                                            mt="-1px"
                                            zIndex="8"
                                            px="10px"
                                            py="5px"
                                            borderRadius="15px"
                                            borderBottomRadius={
                                                metaData &&
                                                (metaData.information ||
                                                    metaData.notes)
                                                    ? '0px'
                                                    : '15px'
                                            }
                                            width={{
                                                base: expanded
                                                    ? '250px'
                                                    : '0px',
                                                xs: expanded ? '270px' : '0px',
                                                sm: expanded ? '300px' : '0px',
                                            }}
                                            minWidth={{
                                                base: expanded
                                                    ? '250px'
                                                    : '0px',
                                                xs: expanded ? '270px' : '0px',
                                                sm: expanded ? '300px' : '0px',
                                            }}
                                            flexDirection="column"
                                            background="#ffff20"
                                            justifyContent="center"
                                            alignItems="center"
                                            transition="all ease-in-out 300ms"
                                            height={
                                                expanded ? 'max-content' : '0px'
                                            }
                                            minHeight={
                                                expanded
                                                    ? `${metaDataAlertsMinHeight}px`
                                                    : '0px'
                                            }
                                            fontSize={{
                                                base: expanded
                                                    ? '0.75rem'
                                                    : '0.0rem',
                                                md: expanded
                                                    ? '0.75rem'
                                                    : '0.0rem',
                                            }}
                                        >
                                            {metaData.alerts.map(
                                                (alerts, i) => (
                                                    <Text
                                                        key={`metaDataAlerts${alerts}-${i}`}
                                                        color="light.100"
                                                        textAlign="center"
                                                        whiteSpace="pre-wrap"
                                                        fontWeight="700"
                                                        lineHeight="1.3"
                                                    >
                                                        {alerts}
                                                    </Text>
                                                )
                                            )}
                                        </Flex>
                                    )}

                                    {metaData && metaData.information && (
                                        <Flex
                                            textShadow="1px 1px 3px rgba(0,0,0,0.15)"
                                            borderRadius="15px"
                                            borderTopRadius={
                                                metaData && metaData.alerts
                                                    ? '0px'
                                                    : '15px'
                                            }
                                            borderBottomRadius={
                                                metaData && metaData.notes
                                                    ? '0px'
                                                    : '15px'
                                            }
                                            background={{
                                                base: 'rgba(0, 0, 0, 0.13)',
                                                md: 'rgba(0, 0, 0, 0.1)',
                                            }}
                                            backdropFilter="blur(10px)"
                                            transition="all ease-in-out 300ms"
                                            flexDirection="column"
                                            justifyContent="center"
                                            alignItems="center"
                                            width={{
                                                base: expanded
                                                    ? '250px'
                                                    : '0px',
                                                xs: expanded ? '270px' : '0px',
                                                sm: expanded ? '300px' : '0px',
                                            }}
                                            minWidth={{
                                                base: expanded
                                                    ? '250px'
                                                    : '0px',
                                                xs: expanded ? '270px' : '0px',
                                                sm: expanded ? '300px' : '0px',
                                            }}
                                            border="1px solid #ffffff"
                                            borderTop={
                                                metaData && metaData.alerts
                                                    ? '1px solid #ffff20'
                                                    : '1px solid #ffffff'
                                            }
                                            overflow="hidden"
                                            minHeight={
                                                expanded
                                                    ? `${metaDataInfoMinHeight}px`
                                                    : '0px'
                                            }
                                            px="8px"
                                            py="6px"
                                            fontSize={{
                                                base: expanded
                                                    ? '0.75rem'
                                                    : '0.0rem',
                                                md: expanded
                                                    ? '0.75rem'
                                                    : '0.0rem',
                                            }}
                                        >
                                            {metaData.information.map(
                                                (info, i) => (
                                                    <Text
                                                        key={`metaDataAlerts${info}-${i}`}
                                                        color="light.10"
                                                        textAlign="center"
                                                        whiteSpace="pre-wrap"
                                                        fontWeight="500"
                                                        lineHeight="1.3"
                                                        p="3px"
                                                    >
                                                        {info}
                                                    </Text>
                                                )
                                            )}
                                        </Flex>
                                    )}
                                    {metaData && metaData.notes && (
                                        <Flex
                                            textShadow="1px 1px 3px rgba(0,0,0,0.15)"
                                            borderRadius="15px"
                                            borderTopRadius={
                                                metaData &&
                                                (metaData.alerts ||
                                                    metaData.information)
                                                    ? '0px'
                                                    : '15px'
                                            }
                                            background={{
                                                base: 'rgba(0, 0, 0, 0.13)',
                                                md: 'rgba(0, 0, 0, 0.1)',
                                            }}
                                            backdropFilter="blur(10px)"
                                            transition="all ease-in-out 300ms"
                                            flexDirection="column"
                                            justifyContent="center"
                                            alignItems="center"
                                            width={{
                                                base: expanded
                                                    ? '250px'
                                                    : '0px',
                                                xs: expanded ? '270px' : '0px',
                                                sm: expanded ? '300px' : '0px',
                                            }}
                                            minWidth={{
                                                base: expanded
                                                    ? '250px'
                                                    : '0px',
                                                xs: expanded ? '270px' : '0px',
                                                sm: expanded ? '300px' : '0px',
                                            }}
                                            border="1px solid #ffffff"
                                            px="8px"
                                            py="6px"
                                            fontSize={{
                                                base: expanded
                                                    ? '0.75rem'
                                                    : '0.0rem',
                                                md: expanded
                                                    ? '0.8rem'
                                                    : '0.0rem',
                                            }}
                                            borderTop={
                                                metaData &&
                                                metaData &&
                                                (metaData.alerts ||
                                                    metaData.information)
                                                    ? '0px solid'
                                                    : '1px solid'
                                            }
                                            borderColor="white"
                                        >
                                            {metaData.notes.map((notes, i) => (
                                                <Text
                                                    key={`metaDataAlerts${notes}-${i}`}
                                                    color="light.10"
                                                    textAlign="center"
                                                    whiteSpace="pre-wrap"
                                                    letterSpacing="0.3px"
                                                    fontWeight="700"
                                                    lineHeight="1.3"
                                                    p="3px"
                                                >
                                                    {notes}
                                                </Text>
                                            ))}
                                        </Flex>
                                    )}
                                </Flex>

                                <Flex // TIMES
                                    minHeight={
                                        expanded
                                            ? `${metaDataTimesMinHeight}px`
                                            : '0px'
                                    }
                                    width={{
                                        base: expanded ? '250px' : '0px',
                                        xs: expanded ? '270px' : '0px',
                                        sm: expanded ? '300px' : '0px',
                                    }}
                                    minWidth={{
                                        base: expanded ? '250px' : '0px',
                                        xs: expanded ? '270px' : '0px',
                                        sm: expanded ? '300px' : '0px',
                                    }}
                                    borderRadius="15px"
                                    opacity={expanded ? '1' : '0'}
                                    visibility={expanded ? 'visible' : 'hidden'}
                                    transition="all ease-in-out 300ms"
                                    flexDirection="column"
                                    justifyContent="center"
                                    alignItems="flex-start"
                                    fontSize={{
                                        base: expanded ? '0.75rem' : '0.0rem',
                                        md: expanded ? '0.85rem' : '0.0rem',
                                    }}
                                    pl="10px"
                                    pt={expanded ? '10px' : '0px'}
                                    pb={{
                                        base: expanded ? '15px' : '0px',
                                        sm: expanded ? '12px' : '0px',
                                    }}
                                    textShadow="1px 1px 3px rgba(0,0,0,0.15)"
                                >
                                    {metaData && metaData.issueTime && (
                                        <Flex
                                            width="100%"
                                            minHeight={{
                                                base: '16px',
                                                md: '20px',
                                            }}
                                            borderRadius="15px"
                                            flexDirection="row"
                                            justifyContent="flex-start"
                                            alignItems="center"
                                        >
                                            <Text
                                                minWidth={{
                                                    base: '75px',
                                                    md: '80px',
                                                }}
                                                pr="7px"
                                                color="light.10"
                                                textAlign="right"
                                                whiteSpace="pre-wrap"
                                                fontWeight="600"
                                                lineHeight="1.3"
                                            >
                                                Issued at:
                                            </Text>
                                            <Text
                                                color="light.10"
                                                textAlign="left"
                                                whiteSpace="pre-wrap"
                                                fontWeight="400"
                                                lineHeight="1.3"
                                                pl="5px"
                                            >
                                                {metaData?.issueTime &&
                                                    formatTimeDate(
                                                        metaData.issueTime,
                                                        timeZone
                                                    )}
                                            </Text>
                                        </Flex>
                                    )}

                                    <Flex
                                        mt="5px"
                                        pt="5px"
                                        width="100%"
                                        flexDirection="column"
                                        justifyContent="flex-start"
                                        alignItems="center"
                                    >
                                        {metaData && metaData.validFromTime && (
                                            <Flex
                                                width="100%"
                                                minHeight={{
                                                    base: '16px',
                                                    md: '20px',
                                                }}
                                                flexDirection="row"
                                                justifyContent="flex-start"
                                                alignItems="center"
                                            >
                                                <Text
                                                    minWidth={{
                                                        base: '75px',
                                                        md: '80px',
                                                    }}
                                                    pr="7px"
                                                    color="light.10"
                                                    textAlign="right"
                                                    whiteSpace="pre-wrap"
                                                    fontWeight="600"
                                                    lineHeight="1.3"
                                                >
                                                    Valid from:
                                                </Text>
                                                <Text
                                                    color="light.10"
                                                    textAlign="left"
                                                    whiteSpace="pre-wrap"
                                                    fontWeight="400"
                                                    lineHeight="1.3"
                                                    pl="5px"
                                                >
                                                    {metaData?.validFromTime &&
                                                        formatTimeAndDay(
                                                            metaData.validFromTime,
                                                            timeZone
                                                        )}
                                                </Text>
                                            </Flex>
                                        )}
                                        {metaData && metaData.validToTime && (
                                            <Flex
                                                width="100%"
                                                minHeight={{
                                                    base: '16px',
                                                    md: '20px',
                                                }}
                                                borderRadius="15px"
                                                flexDirection="row"
                                                justifyContent="flex-start"
                                                alignItems="center"
                                            >
                                                <Text
                                                    minWidth={{
                                                        base: '75px',
                                                        md: '80px',
                                                    }}
                                                    pr="7px"
                                                    color="light.10"
                                                    textAlign="right"
                                                    whiteSpace="pre-wrap"
                                                    fontWeight="600"
                                                    lineHeight="1.3"
                                                >
                                                    to:
                                                </Text>
                                                <Text
                                                    color="light.10"
                                                    textAlign="left"
                                                    whiteSpace="pre-wrap"
                                                    fontWeight="400"
                                                    lineHeight="1.3"
                                                    pl="5px"
                                                >
                                                    {metaData?.validToTime &&
                                                        formatTimeAndDay(
                                                            metaData.validToTime,
                                                            timeZone
                                                        )}
                                                </Text>
                                            </Flex>
                                        )}
                                    </Flex>
                                </Flex>
                            </Flex>
                        </Flex>

                        {/* *** List Expand Button  *** */}
                        <Flex
                            display={{ base: 'flex', md: 'flex' }}
                            w="40px"
                            h="40px"
                            justifyContent="center"
                            alignItems="center"
                            position="absolute"
                            right={{
                                base: '-2px',
                                md: '-3px',
                            }}
                            bottom="-3px"
                            zIndex="2"
                        >
                            <IconButton
                                {...noFocus}
                                onClick={() => {
                                    setExpanded(!expanded)
                                    isMobile &&
                                        !expanded &&
                                        closeLayerSelector()
                                }}
                                {...focusShadow}
                                w={{ base: '36px', md: '36px' }}
                                minWidth="36px"
                                maxWidth="36px"
                                h={{ base: '36px', md: '36px' }}
                                borderRadius="100%"
                                backdropFilter={
                                    expanded ? 'blur(15px)' : 'none'
                                }
                                boxShadow={expanded ? buttonShadowOpen : 'none'}
                                background={{
                                    base: expanded
                                        ? expandButton
                                        : 'transparent',
                                    md: expanded ? expandButton : 'transparent',
                                }}
                                opacity={expanded ? '1' : '0.7'}
                                borderColor="rgba(255,255,255,0.9)"
                                _focus={{
                                    backgroundColor: expanded
                                        ? expandButtonHover
                                        : 'transparent',
                                    boxShadow: expanded
                                        ? buttonShadowOpen
                                        : 'none',
                                }}
                                _active={{
                                    backgroundColor: expanded
                                        ? expandButtonHover
                                        : 'transparent',
                                    boxShadow: expanded
                                        ? buttonShadowOpen
                                        : 'none',
                                }}
                                _hover={{
                                    opacity: '1',
                                    backgroundColor: expanded
                                        ? expandButtonHover
                                        : 'transparent',
                                    borderColor: 'light.10',
                                }}
                                transition="all ease-in-out 300m"
                                zIndex="1"
                                position="absolute"
                                icon={
                                    <Flex
                                        justifyContent="center"
                                        alignItems="center"
                                        border={
                                            expanded ? '0px solid' : '2px solid'
                                        }
                                        borderColor="inherit"
                                        borderRadius="20px"
                                        width={{
                                            base: expanded ? '36px' : '32px',
                                            md: expanded ? '36px' : '32px',
                                        }}
                                        height={{
                                            base: expanded ? '36px' : '32px',
                                            md: expanded ? '36px' : '32px',
                                        }}
                                        transition="all ease-in-out 300m"
                                    >
                                        <InfoIcon
                                            color={
                                                expanded ? buttonColor : 'white'
                                            }
                                            width="24px"
                                            height="24px"
                                        />
                                    </Flex>
                                }
                            />

                            <Box //OPENING PULSE
                                position="absolute"
                                w={expanded ? '55px' : '0px'}
                                h={expanded ? '55px' : '0px'}
                                margin="auto"
                                borderRadius="100%"
                                background={
                                    expanded ? buttonPulse : 'transparent'
                                }
                                opacity={expanded ? '0' : '1'}
                                transition="width ease-in-out-out 100ms, height ease-in-out-out 100ms, opacity ease-in-out-in 450ms"
                            ></Box>
                            <Box // CLOSING PULSE
                                position="absolute"
                                w={!expanded ? '55px' : '0px'}
                                h={!expanded ? '55px' : '0px'}
                                margin="auto"
                                borderRadius="100%"
                                background={
                                    !expanded ? buttonPulse : 'transparent'
                                }
                                opacity={!expanded ? '0' : '1'}
                                transition="width ease-in-out-out 100ms, height ease-in-out-out 100ms, opacity ease-in-out-in 450ms"
                            ></Box>
                        </Flex>
                    </>
                )}
            </Flex>
        </>
    )
}
