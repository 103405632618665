import React, { useEffect, useCallback, useState } from 'react'
import { useRecoilValue, useSetRecoilState } from 'recoil'
import {
    mapGlLostState,
    isBriefingOnlyState,
    mapDisabledState,
    briefingStandardState,
    fullscreenModalVisibleState,
    searchFocusState,
    showOnlineUsersButtonState,
    hideNetworkConnectionLostState,
    setModalVisibilityState,
} from '../../../globalState'
import {
    Modal,
    Flex,
    ModalOverlay,
    ModalContent,
    ModalHeader,
    ModalCloseButton,
    ModalFooter,
    ModalBody,
    useDisclosure,
    Button,
    LightMode,
    Text,
    Icon,
    Box,
    ButtonGroup,
    Link,
    Fade,
} from '@chakra-ui/react'
import { ReactComponent as WarningIcon } from '../../../icons/assets/error-warning.svg'
import { isIOS, isMobileOnly } from 'react-device-detect'
import RefreshIcon from '../../../icons/menu-icons/RefreshIcon'

export default function MapGLLostModal() {
    const mapGlLost = useRecoilValue(mapGlLostState)
    const mapDisabled = useRecoilValue(mapDisabledState)
    const isBriefingOnly = useRecoilValue(isBriefingOnlyState)
    const briefingStandard = useRecoilValue(briefingStandardState)
    const fullscreen = useRecoilValue(fullscreenModalVisibleState)
    const [showErrorBadge, setShowErrorBadge] = useState(false)
    const { isOpen, onOpen, onClose } = useDisclosure()

    useEffect(() => {
        if (
            // open Map Load Error modal if MapBox detects failure with MapGL
            mapGlLost &&
            !isBriefingOnly &&
            !mapDisabled &&
            !briefingStandard &&
            !fullscreen
        ) {
            onOpen()
        }
    }, [
        mapGlLost,
        isBriefingOnly,
        mapDisabled,
        briefingStandard,
        fullscreen,
        onOpen,
        onClose,
    ])

    function refreshPage() {
        setTimeout(() => window.location.reload())
    }

    const onCloseHandler = useCallback(() => {
        setShowErrorBadge(true)
        onClose()
    }, [onClose])

    const setModalVisibility = useSetRecoilState(setModalVisibilityState)

    const setShowFaqs = useCallback(
        (value) => {
            setModalVisibility({ id: 'faqs', value })
        },
        [setModalVisibility]
    )

    const noFocus = {
        _focus: { boxShadow: 'none' },
        _focusVisible: { boxShadow: 'none !important' },
    }

    function LayerErrorButton({ handleOpen, show }) {
        const searchFocused = useRecoilValue(searchFocusState)
        const onlineUsers = useRecoilValue(showOnlineUsersButtonState)
        const hideNetworkConnectionLost = useRecoilValue(
            hideNetworkConnectionLostState
        )

        return (
            <Fade in={show}>
                <Box
                    display={show ? 'block' : 'none'}
                    direction={'row'}
                    onClick={handleOpen}
                    pos={'fixed'}
                    top={{
                        base: hideNetworkConnectionLost ? '115px' : '70px',
                        md: hideNetworkConnectionLost ? '60px' : '19px',
                    }}
                    left={{
                        base: '50vw',
                        md: onlineUsers ? '248px' : '200px',
                    }}
                    opacity={{
                        base: '1',
                        md: searchFocused ? '0' : '1',
                    }}
                    visibility={{
                        base: 'visible',
                        md: searchFocused ? 'hidden' : 'visible',
                    }}
                    transform={{ base: 'translate(-50%, 0)', md: 'none' }}
                    transition="all ease-out 200ms"
                    _hover={{ cursor: 'pointer' }}
                >
                    <Flex
                        alignContent={'center'}
                        alignItems={'center'}
                        bg="rgb(241, 73, 73, .7)"
                        backdropFilter="blur(2px)"
                        px="12px"
                        height="34px"
                        borderRadius="30px"
                        width={{ base: '155px', md: 'auto' }}
                        boxShadow={'0px 3px 20px -7px rgba(0, 0, 0, 0.2)'}
                        _focus={{
                            border: 'none',
                            background: 'red.500',
                        }}
                        _hover={{ background: 'red.500' }}
                        _active={{ background: 'red.500' }}
                        whiteSpace="pre"
                    >
                        <Icon
                            as={WarningIcon}
                            color={'white'}
                            width={6}
                            height={6}
                        />
                        <Box pl="7px" pr="5px" color="white">
                            <Text fontWeight="700" fontSize="0.75rem">
                                Map Load Error
                            </Text>
                            <Text mt="-6px" fontWeight="400" fontSize="0.65rem">
                                Click to refresh
                            </Text>
                        </Box>
                    </Flex>
                </Box>
            </Fade>
        )
    }

    return (
        <>
            <LayerErrorButton
                show={showErrorBadge}
                handleOpen={() => refreshPage()()}
            />
            <LightMode>
                <Modal
                    variant="alert"
                    size="5xl"
                    isOpen={isOpen}
                    onClose={onCloseHandler}
                    closeOnOverlayClick={false}
                    isCentered
                    trapFocus={false}
                    scrollBehavior={'inside'}
                >
                    <ModalOverlay
                        borderWidth={4}
                        borderColor="#FF4F44"
                        borderTopRadius={{
                            // reset these values to '0' for now pending further testing on mobile devices
                            base: isIOS && isMobileOnly ? '0px' : '0px',
                            md: '0px',
                        }}
                        borderBottomRadius={{
                            base: isIOS && isMobileOnly ? '0px' : '0px',
                            md: '0px',
                        }}
                        bg={'blackAlpha.600'}
                    />

                    <ModalContent borderRadius="15" bg="white">
                        <ModalHeader
                            bg="white"
                            color="light.100"
                            borderBottom="1px solid #eaeaea"
                            paddingStart="6"
                            textAlign="center"
                        >
                            <ModalCloseButton color="gray.500" />
                            <Flex
                                justifyContent="center"
                                alignItems="center"
                                mx="auto"
                                pr={2}
                            >
                                <Icon
                                    as={WarningIcon}
                                    color="red.500"
                                    width="34px"
                                    height="34px"
                                />
                                <Box pl={2} pr={3} fontFamily="Open Sans">
                                    Map Load Error
                                </Box>
                            </Flex>
                        </ModalHeader>
                        <ModalBody
                            textAlign="center"
                            bg="white"
                            borderBottom="1px solid"
                            borderColor="gray.100"
                            fontSize={{ base: '0.8rem', md: '0.9rem' }}
                            boxShadow="inset 0px 10px 15px -5px rgba(0,0,0,0.07)"
                        >
                            <Flex
                                overflowX="hidden"
                                px={{ base: 0, md: 10 }}
                                pt={5}
                                alignItems="baseline"
                                flexDirection="column"
                                textAlign="center"
                                width="100%"
                                gap="10px"
                                fontSize="0.8rem"
                            >
                                <Text
                                    fontSize="0.95rem"
                                    fontWeight="600"
                                    textAlign="center"
                                    width="100%"
                                    px="10px"
                                    pt="5px"
                                    color="gray.500"
                                >
                                    We apologise for the inconvenience, but it
                                    seems the map has unexpectedly failed to
                                    load as expected
                                </Text>
                                <Text
                                    textAlign="center"
                                    width="100%"
                                    fontSize="0.85rem"
                                >
                                    We have been unable to determine the
                                    specific cause of this error
                                </Text>
                                {/* <Divider mt="15px" mb="15px" /> */}
                                <Flex
                                    flexDirection="column"
                                    width="100%"
                                    justifyContent="center"
                                    alignItems={'center'}
                                    gap="10px"
                                    mt="20px"
                                    mb="15px"
                                    px="15px"
                                    pt="15px"
                                    pb="17px"
                                    border="1px solid"
                                    borderColor="gray.200"
                                    bg="gray.50"
                                >
                                    <Flex
                                        flexDirection="column"
                                        width="100%"
                                        justifyContent="center"
                                        alignItems={'center'}
                                        gap="5px"
                                        pt="5px"
                                    >
                                        <Text
                                            fontSize="0.9rem"
                                            color="gray.500"
                                            fontWeight="700"
                                            textTransform={'uppercase'}
                                        >
                                            Immediate Solution
                                        </Text>
                                        <Text
                                            fontWeight="600"
                                            fontSize="0.85rem"
                                        >
                                            Please refresh your browser now to
                                            reload the PreFlight
                                            web-application.
                                            <br /> If you are using a mobile
                                            device, you may need to close and
                                            restart the application running
                                            PreFlight.
                                        </Text>
                                    </Flex>
                                    <Flex
                                        pt="12px"
                                        flexDirection="column"
                                        width="100%"
                                        justifyContent="center"
                                        alignItems={'center'}
                                        gap="5px"
                                        mb="5px"
                                    >
                                        <Text
                                            fontSize="0.9rem"
                                            fontWeight="700"
                                            color="gray.500"
                                            textTransform={'uppercase'}
                                        >
                                            Recommended Action
                                        </Text>
                                        <Text
                                            fontWeight="600"
                                            fontSize="0.85rem"
                                        >
                                            We recommend updating your device to
                                            the latest software version if an
                                            update is available.
                                        </Text>
                                    </Flex>
                                    <Text
                                        fontWeight="400"
                                        fontSize="0.85rem"
                                        pt="10px"
                                        mb="5px"
                                    >
                                        If the issue persists, please get in
                                        touch with{' '}
                                        <span style={{ fontWeight: '600' }}>
                                            PreFlight Support
                                        </span>{' '}
                                        by submitting a request using the{' '}
                                        <strong>Send us Feedback</strong> tool
                                        within the PreFlight web-app, or
                                        emailing:{' '}
                                        <Link
                                            color="light.200"
                                            href="mailto:support@aeropath.co.nz"
                                        >
                                            support@aeropath.co.nz
                                        </Link>
                                        .
                                    </Text>
                                </Flex>

                                <Flex
                                    flexDirection="column"
                                    width="100%"
                                    justifyContent="center"
                                    alignItems={'center'}
                                    gap="10px"
                                    px="15px"
                                    pt="5px"
                                    pb="7px"
                                >
                                    <Text
                                        fontSize="0.9rem"
                                        fontWeight="700"
                                        color="gray.500"
                                    >
                                        Additional Recommendations
                                    </Text>
                                    <Text>
                                        Additionally, we suggest optimising
                                        device performance by limiting the
                                        number of apps concurrently running in
                                        the background and periodically clearing
                                        your browser cache.
                                    </Text>
                                    <Text>
                                        Certain background apps on mobiles and
                                        tablets can occasionally impact device
                                        web browser performance, and adopting
                                        this proactive approach can assist users
                                        in identifying and mitigating any
                                        potential factors that may affect the
                                        efficiency of their device.
                                    </Text>
                                    <Text>
                                        Moreover, sometimes certain cookies and
                                        cached files that may relate to
                                        performance issues could be stored in
                                        the browser's history. Clearing the
                                        cache is beneficial in removing any
                                        potential memory blockages that might
                                        contribute to the identified concerns.
                                    </Text>
                                    <Text>
                                        Please refer to the{' '}
                                        <Link
                                            onClick={() => {
                                                setShowFaqs(true)
                                            }}
                                            color="light.200"
                                            fontWeight="600"
                                        >
                                            Frequently Asked Questions
                                        </Link>{' '}
                                        for further assistance on how to
                                        optimise device performance for
                                        PreFlight.
                                    </Text>
                                </Flex>

                                <Flex
                                    flexDirection="column"
                                    width="100%"
                                    justifyContent="center"
                                    alignItems={'center'}
                                    gap="10px"
                                    mt="20px"
                                    px="15px"
                                    pt="12px"
                                    pb="15px"
                                    border="1px solid"
                                    borderColor="gray.200"
                                    bg="gray.50"
                                >
                                    <Text
                                        fontSize="0.9rem"
                                        fontWeight="700"
                                        color="gray.500"
                                    >
                                        Disable Map Option
                                    </Text>

                                    <Text>
                                        We recognise that the advanced
                                        functionalities of the map may
                                        occasionally place some demands on the
                                        performance of certain devices,
                                        particularly older devices or those with
                                        limited graphics capabilities. While the
                                        map generally runs smoothly on most
                                        modern devices, PreFlight offers a{' '}
                                        <span style={{ fontWeight: '600' }}>
                                            Disable Map
                                        </span>{' '}
                                        option for users who may encounter
                                        performance issues, ensuring a more
                                        responsive experience.
                                    </Text>
                                    <Text>
                                        Disabling or enabling the map is easy.
                                        Simply navigate to the{' '}
                                        <span style={{ fontWeight: '700' }}>
                                            Settings
                                        </span>{' '}
                                        tab within your{' '}
                                        <span style={{ fontWeight: '700' }}>
                                            Profile
                                        </span>{' '}
                                        where you'll find the{' '}
                                        <span style={{ fontWeight: '600' }}>
                                            Disable Map
                                        </span>{' '}
                                        toggle switch.
                                    </Text>
                                    <Text>
                                        This option provides the flexibility to
                                        temporarily deactivate the map and
                                        related functions, which may help
                                        improve performance on your device,
                                        should you encounter performance issues.
                                        When the map is disabled, you can still
                                        access all non-map-based features, and a
                                        full briefing from standard briefing
                                        areas.
                                    </Text>
                                    <Text pb="5px">
                                        This feature is optional, and you can
                                        re-enable the map at any time by turning
                                        off this setting.
                                    </Text>
                                </Flex>
                                <Text
                                    fontSize="0.8rem"
                                    textAlign="center"
                                    width="100%"
                                    fontWeight="500"
                                    fontStyle="italic"
                                    mt="20px"
                                    mb="45px"
                                >
                                    We appreciate your understanding as we work
                                    to ensure a seamless experience for our
                                    users.
                                </Text>
                            </Flex>
                        </ModalBody>
                        <ModalFooter
                            px="15px"
                            pt="15px"
                            pb="16px"
                            justifyContent="center"
                            alignItems="center"
                        >
                            <ButtonGroup
                                gap="10px"
                                width="100%"
                                justifyContent="center"
                            >
                                <Button
                                    colorScheme="gray"
                                    color="light.100"
                                    variant="outline"
                                    borderRadius="30px"
                                    mr="5px"
                                    size="sm"
                                    onClick={onCloseHandler}
                                    w="100%"
                                    maxWidth="200px"
                                    _hover={{
                                        background: 'light.20',
                                    }}
                                    _active={{
                                        background: 'light.20',
                                    }}
                                    {...noFocus}
                                >
                                    Close
                                </Button>
                                <Button
                                    size="sm"
                                    colorScheme="red"
                                    variant="solid"
                                    leftIcon={
                                        <RefreshIcon
                                            color="white"
                                            boxSize={4}
                                        />
                                    }
                                    borderRadius="30px"
                                    onClick={refreshPage}
                                    w="100%"
                                    maxWidth="200px"
                                    _focus={{ border: 'none' }}
                                    {...noFocus}
                                >
                                    Refresh
                                </Button>
                            </ButtonGroup>
                        </ModalFooter>
                    </ModalContent>
                </Modal>
            </LightMode>
        </>
    )
}
