import React, { useState, useEffect, useCallback, useRef, useMemo } from 'react'
import { useRecoilState, useRecoilValue } from 'recoil'
import { Fade, Tooltip, useBreakpointValue } from '@chakra-ui/react'
import { Slider, Rail, Handles, Tracks, Ticks } from 'react-compound-slider'
import { SliderRail, Handle, MiniHandle, Track, Tick } from './components/'
import { useTier } from '../../../../auth/hooks'
import {
    altitudeModeState,
    selectedAltitudeState,
    selectedLowerAltitudeState,
    selectedUpperAltitudeState,
    dataModeState,
    layerSelectionState,
    simulateFreeUserState,
    messagesButtonState,
    displayDisclaimerShowState,
    mfaVerifiedState,
    mapDisabledState,
    basemapState,
    // wipMenuItemsState,
} from '../../../../globalState'
import { Center, Flex } from '@chakra-ui/layout'
import { isMobile, isIOS } from 'react-device-detect'
import PremiumBadge from '../../PremiumBadge'

export default function AltitudeSlider({
    mapToolsExpanded,
    altitudeSliderExpanded,
}) {
    const userTier = useTier()

    // upper limit is 15,000 feet
    const mode = useRecoilValue(altitudeModeState)
    const dataMode = useRecoilValue(dataModeState)
    const layerSelection = useRecoilValue(layerSelectionState)

    const [altitude, setAltitude] = useRecoilState(selectedAltitudeState)
    const [lowerAltitude, setLowerAltitude] = useRecoilState(
        selectedLowerAltitudeState
    )
    const [upperAltitude, setUpperAltitude] = useRecoilState(
        selectedUpperAltitudeState
    )
    const mapDisabled = useRecoilValue(mapDisabledState)
    const basemap = useRecoilValue(basemapState)
    const showMapEnhanceButton = basemap === 'vnc' || basemap === 'linztopo'

    const [activeHandle, setActiveHandle] = useState(null)
    // const wipMenuItems = useRecoilValue(wipMenuItemsState)
    const [hover, setHover] = useState(false)
    const [tooltipsVisible, setTooltipsVisible] = useState(true)
    const [timeoutActive, setTimeoutActive] = useState(true)
    const endTimer = useRef(null)

    const minAltitude = 0
    const [maxAltitude, setMaxAltitude] = useState(10000)
    const [steps, setSteps] = useState(20)
    const numberOfTicks = 4
    const messagesButton = useRecoilValue(messagesButtonState)
    const displayDisclaimerShow = useRecoilValue(displayDisclaimerShowState)
    const simulateFreeUser = useRecoilValue(simulateFreeUserState)
    const mfaVerified = useRecoilValue(mfaVerifiedState)
    const isPremium =
        simulateFreeUser || !mfaVerified ? userTier >= 1 : userTier === 1

    const reduceHeight =
        messagesButton ||
        (((layerSelection && layerSelection.met.sigmet) ||
            (layerSelection && layerSelection.met.sigwx)) &&
            dataMode === 'met') ||
        (((layerSelection && layerSelection.advs.sigmet) ||
            (layerSelection && layerSelection.advs.sigwx)) &&
            dataMode === 'advs')

    useEffect(() => {
        if (
            !isPremium &&
            userTier > 1 &&
            dataMode === 'met' &&
            layerSelection.met.aaw
        ) {
            setMaxAltitude(10000)
            setSteps(10)
        } else if (!isPremium && userTier > 1) {
            setMaxAltitude(60000)
            setUpperAltitude(60000)
            setSteps(60)
        }
    }, [
        isPremium,
        userTier,
        maxAltitude,
        setUpperAltitude,
        dataMode,
        layerSelection.met.aaw,
    ])

    const [ticks, setTicks] = useState([])

    const aawAltitudes = useMemo(() => [1000, 3000, 5000, 7000, 10000], [])

    const findClosestAAWAltitude = useCallback(() => {
        const indexArr = aawAltitudes.map(function (k) {
            return Math.abs(k - altitude)
        })
        const min = Math.min.apply(Math, indexArr)
        return aawAltitudes[indexArr.indexOf(min)]
    }, [aawAltitudes, altitude])

    const onChangeHandler = useCallback(
        (a) => {
            if (a.length === 1) {
                if (dataMode === 'met' && layerSelection.met.aaw) {
                    setAltitude(findClosestAAWAltitude())
                } else {
                    setAltitude(a[0])
                }
            } else {
                if (a[0] - a[1] > 500) {
                    setLowerAltitude(a[1])
                    setUpperAltitude(a[0])
                }
            }
        },
        [
            dataMode,
            layerSelection.met.aaw,
            findClosestAAWAltitude,
            setAltitude,
            setLowerAltitude,
            setUpperAltitude,
        ]
    )

    const onUpdateHandler = useCallback(
        (a) => {
            if (a.length === 1) {
                if (dataMode === 'met' && layerSelection.met.aaw) {
                    setAltitude(findClosestAAWAltitude())
                } else {
                    setAltitude(a[0])
                }
            } else {
                if (a[0] - a[1] > 500) {
                    setLowerAltitude(a[1])
                    setUpperAltitude(a[0])
                }
            }
        },
        [
            dataMode,
            layerSelection.met.aaw,
            findClosestAAWAltitude,
            setAltitude,
            setLowerAltitude,
            setUpperAltitude,
        ]
    )

    const onSlideStartHandler = () => {
        setTimeoutActive(false)
        setTooltipsVisible(true)
    }

    // TODO Had to "double set" the altitude for the aawAlt in order to get this to work. Would be good to investigate why
    const onSlideEndHandler = useCallback(
        (a) => {
            !hover && setTimeoutActive(true)
            if (a.length === 1) {
                setAltitude(a[0])
            } else {
                if (a[0] - a[1] > 500) {
                    setLowerAltitude(a[1])
                    setUpperAltitude(a[0])
                }
            }
        },
        [
            setAltitude,
            setLowerAltitude,
            setUpperAltitude,
            setTimeoutActive,
            hover,
        ]
    )

    useEffect(() => {
        function tickConstructor() {
            setTicks(
                Array.apply(null, Array(numberOfTicks)).map(
                    (val, idx) => idx * (maxAltitude / numberOfTicks)
                )
            )
        }

        tickConstructor()
    }, [maxAltitude, numberOfTicks])

    const offsetHandler = () => {
        return upperAltitude - lowerAltitude < 2000 ? true : false
    }

    // Tooltip labels for altitude slider. Times out after 4 seconds. Active when selected.
    useEffect(() => {
        if (timeoutActive) {
            endTimer.current = setTimeout(() => {
                setTimeoutActive(false)
                setTooltipsVisible(false)
            }, 4000)
        }
        return () => {
            clearTimeout(endTimer.current)
        }
    }, [timeoutActive])

    // when the map tools and altitude slider are expanded, display the tooltips
    useEffect(() => {
        if (mapToolsExpanded || altitudeSliderExpanded) {
            setTooltipsVisible(true)
            setTimeoutActive(true)
        }
    }, [mapToolsExpanded, altitudeSliderExpanded])

    const sliderHeight = useBreakpointValue({
        base: mapDisabled ? '55vh' : '280px',
        md: mapDisabled ? '400px' : '320px',
    })
    const tickColor = useBreakpointValue({
        base: '#ffffff',
        md: mapDisabled ? '#718096' : '#ffffff',
    })
    const tickTextShadow = useBreakpointValue({
        base: '1px 1px 3px rgba(0,0,0,0.5)',
        md: mapDisabled ? 'none' : '1px 1px 3px rgba(0,0,0,0.5)',
    })

    const getSliderHeight = () => {
        let offset = 555 // default

        if (mapDisabled) {
            offset = 400
        } else if (isMobile && isIOS) {
            if (
                reduceHeight ||
                (displayDisclaimerShow && dataMode === 'aero')
            ) {
                offset = showMapEnhanceButton ? 650 : 605
            } else {
                offset = showMapEnhanceButton ? 615 : 570
            }
        } else if (
            reduceHeight ||
            (displayDisclaimerShow && dataMode === 'aero')
        ) {
            offset = showMapEnhanceButton ? 625 : 580
        } else if (showMapEnhanceButton) {
            offset = 600
        }

        return `calc(100vh - ${offset}px)`
    }

    const sliderStyle = {
        width: 6,
        height: getSliderHeight(),
        maxHeight: sliderHeight,
        transition: 'height ease 600ms',
        cursor: 'pointer',
        top: 215,
        right: 1,
    }

    useEffect(() => {
        if (!aawAltitudes.includes(altitude)) {
            setAltitude(findClosestAAWAltitude())
        }
    }, [altitude, setAltitude, aawAltitudes, findClosestAAWAltitude])

    return (
        <Flex
            w={'40px'}
            onMouseEnter={() => {
                setHover(true)
                setTimeoutActive(false)
                setTooltipsVisible(true)
            }}
            onMouseLeave={() => {
                setHover(false)
                setTimeoutActive(true)
            }}
        >
            <Center w={'100%'} pos="relative">
                <Slider
                    vertical
                    reversed
                    mode={2}
                    step={maxAltitude / steps}
                    domain={[minAltitude, maxAltitude]}
                    rootStyle={sliderStyle}
                    onChange={onChangeHandler}
                    onUpdate={onUpdateHandler}
                    onSlideStart={onSlideStartHandler}
                    onSlideEnd={onSlideEndHandler}
                    values={
                        mode === 'single'
                            ? [altitude]
                            : [lowerAltitude, upperAltitude]
                    }
                >
                    <Rail>
                        {({ getRailProps }) => (
                            <SliderRail getRailProps={getRailProps} />
                        )}
                    </Rail>
                    <Handles>
                        {({ handles, getHandleProps }) => (
                            <>
                                {handles.map((handle, index) => {
                                    return (
                                        <div key={handle.id}>
                                            {
                                                <Fade in={tooltipsVisible}>
                                                    <Handle
                                                        index={index}
                                                        offset={offsetHandler()}
                                                        handle={handle}
                                                        domain={[
                                                            minAltitude,
                                                            maxAltitude,
                                                        ]}
                                                        setActiveHandle={
                                                            setActiveHandle
                                                        }
                                                        getHandleProps={
                                                            getHandleProps
                                                        }
                                                    />
                                                </Fade>
                                            }
                                            <MiniHandle
                                                handle={handle}
                                                domain={[
                                                    minAltitude,
                                                    maxAltitude,
                                                ]}
                                                activeHandle={activeHandle}
                                                setActiveHandle={
                                                    setActiveHandle
                                                }
                                                getHandleProps={getHandleProps}
                                            />
                                        </div>
                                    )
                                })}
                            </>
                        )}
                    </Handles>
                    <Tracks right={false}>
                        {({ tracks, getTrackProps }) => (
                            <div>
                                {tracks.map(({ id, source, target }) => (
                                    <Track
                                        key={id}
                                        source={source}
                                        target={target}
                                        getTrackProps={getTrackProps}
                                    />
                                ))}
                            </div>
                        )}
                    </Tracks>
                    <Ticks values={[...ticks, maxAltitude]}>
                        {({ ticks }) => (
                            <div>
                                {ticks.map((tick) => (
                                    <Tick
                                        key={tick.id}
                                        tick={tick}
                                        count={ticks.length}
                                        format={(value) => {
                                            return value === 0
                                                ? 'SFC'
                                                : Math.round(value) < 13500
                                                ? `${
                                                      Math.round(value) >= 10000
                                                          ? Math.round(
                                                                value
                                                            ).toLocaleString(
                                                                'en'
                                                            )
                                                          : Math.round(value)
                                                  } ft`
                                                : `FL${Math.round(value) / 100}`
                                        }}
                                        tickColor={tickColor}
                                        tickTextShadow={tickTextShadow}
                                    />
                                ))}
                            </div>
                        )}
                    </Ticks>
                </Slider>
            </Center>

            {isPremium && ( // PREMIUM BADGE
                <Flex position="absolute" right="4px" mt="-19px">
                    <Tooltip
                        label="> 10,000 ft Premium Only"
                        hasArrow
                        borderRadius="7px"
                        height="26px"
                        fontSize="0.8rem"
                        alignItems="center"
                        display="flex"
                        placement="left"
                        mr="5px"
                        color="light.10"
                        bg="brand.premium"
                    >
                        <PremiumBadge />
                    </Tooltip>
                </Flex>
            )}
        </Flex>
    )
}
