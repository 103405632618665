import React, { useCallback, useEffect } from 'react'
import {
    Modal,
    Button,
    ModalOverlay,
    ModalContent,
    ModalHeader,
    ModalBody,
    ModalFooter,
    useDisclosure,
    LightMode,
    Flex,
    Text,
    Icon,
    Link,
    Box,
} from '@chakra-ui/react'
import { useSetRecoilState, useRecoilValue } from 'recoil'
import { setModalVisibilityState, modalVisibilityState } from '../globalState'

import { ReactComponent as ChromeIcon } from '../map/modals/assets/chromeIcon.svg'
import { ReactComponent as FirefoxIcon } from '../map/modals/assets/firefoxIcon.svg'
import { ReactComponent as SafariIcon } from '../map/modals/assets/safariIcon.svg'

export default function DeviceRequirementsModal(props) {
    const { deviceWarning, setDeviceWarning } = props
    const modalVisibility = useRecoilValue(modalVisibilityState)
    const setModalVisibility = useSetRecoilState(setModalVisibilityState)

    const setShow = useCallback(
        (value) => {
            setModalVisibility({ id: 'deviceRequirements', value })
        },
        [setModalVisibility]
    )

    const { isOpen, onOpen, onClose } = useDisclosure()

    useEffect(() => {
        modalVisibility.deviceRequirements ? onOpen() : onClose()
    }, [modalVisibility.deviceRequirements, onOpen, onClose])

    function onCloseHandler() {
        if (deviceWarning) {
            setShow(false)
            setDeviceWarning(false)
        } else setShow(false)
    }

    const noFocus = {
        _focus: { boxShadow: 'none !important' },
    }

    return (
        <>
            <LightMode>
                <Modal
                    variant={deviceWarning && 'deviceWarning'}
                    size="3xl"
                    onClose={onCloseHandler}
                    isOpen={isOpen}
                    closeOnOverlayClick={false}
                    isCentered
                    trapFocus={false}
                >
                    {!deviceWarning && <ModalOverlay />}
                    <ModalContent mx="15px">
                        <ModalHeader
                            zIndex="1"
                            textAlign="center"
                            background="white"
                            color="light.200"
                            boxShadow="0px 0px 15px -1px rgba(0,0,0,0.25)"
                        >
                            Device Requirements
                        </ModalHeader>
                        <ModalBody
                            background="light.20"
                            style={{ padding: 0 }}
                            fontSize="0.8rem"
                            fontFamily="'Poppins','Open Sans',sans-serif"
                        >
                            <Flex
                                maxHeight="65vh"
                                overflowY="auto"
                                overflowX="hidden"
                                px={{ base: 6, md: 10 }}
                                pt={5}
                                pb={8}
                                alignItems="baseline"
                                flexDirection="column"
                            >
                                <Text pt="10px">
                                    This web application has been designed to
                                    make it easier to access and interpret
                                    pre-flight information. The key to this is
                                    making it more visual and therefore the
                                    dynamic map is a core part of the
                                    application.
                                </Text>
                                <Text pt="10px">
                                    However, because of the amount of
                                    information processed and presented, this
                                    does come at a small cost in that it
                                    requires newer devices and computers to run
                                    smoothly.
                                </Text>
                                <Text pt="25px" fontSize="0.9rem">
                                    <strong>Recommended Browsers</strong>
                                </Text>
                                <Flex
                                    pt="10px"
                                    width={{ base: '100%', sm: 'auto' }}
                                    flexDirection="column"
                                    justifyContent="space-evenly"
                                    alignItems="center"
                                    px="9px"
                                >
                                    <Flex
                                        width={{ base: '100%', sm: 'auto' }}
                                        justifyContent="space-evenly"
                                        alignItems="center"
                                    >
                                        <Flex
                                            width="80px"
                                            justifyContent="center"
                                            alignItems="center"
                                        >
                                            <Icon
                                                as={ChromeIcon}
                                                boxSize="10"
                                                mx="10px"
                                                mt="1px"
                                                pl="1px"
                                            />
                                        </Flex>
                                        <Flex
                                            width="80px"
                                            justifyContent="center"
                                            alignItems="center"
                                            mt="-2px"
                                        >
                                            <Icon
                                                as={FirefoxIcon}
                                                boxSize="10"
                                                mx="10px"
                                            />
                                        </Flex>
                                        <Flex
                                            width="80px"
                                            justifyContent="center"
                                            alignItems="center"
                                        >
                                            <Icon
                                                as={SafariIcon}
                                                boxSize="10"
                                                mx="10px"
                                            />
                                        </Flex>
                                    </Flex>
                                    <Flex
                                        width={{ base: '100%', sm: 'auto' }}
                                        justifyContent="space-evenly"
                                        alignItems="center"
                                        fontSize="0.7rem"
                                        borderBottom="1px solid #e2e5e9"
                                        pt="5px"
                                        pb="5px"
                                        color="gray.400"
                                        fontWeight="300"
                                    >
                                        <Text width="80px" textAlign="center">
                                            Chrome
                                        </Text>
                                        <Text width="80px" textAlign="center">
                                            Firefox
                                        </Text>
                                        <Text width="80px" textAlign="center">
                                            Safari
                                        </Text>
                                    </Flex>
                                    <Flex
                                        color="gray.500"
                                        fontSize="0.8rem"
                                        fontWeight="500"
                                        pt="5px"
                                        width={{ base: '100%', sm: 'auto' }}
                                        justifyContent="space-evenly"
                                        alignItems="center"
                                    >
                                        <Text
                                            pl="2px"
                                            width="80px"
                                            textAlign="center"
                                        >
                                            117+
                                        </Text>
                                        <Text
                                            pl="2px"
                                            width="80px"
                                            textAlign="center"
                                        >
                                            102+
                                        </Text>
                                        <Text
                                            pl="2px"
                                            width="80px"
                                            textAlign="center"
                                        >
                                            16+
                                        </Text>
                                    </Flex>
                                </Flex>
                                <Text pl="10px" pt="10px">
                                    We recommend the current version of Chrome,
                                    Firefox or Safari.{' '}
                                </Text>
                                <Text pt="25px" fontSize="0.9rem">
                                    <strong>Recommended Devices</strong>
                                </Text>
                                <Text pl="10px" pt="10px">
                                    Preflight will run on Apple, Android and
                                    Windows devices, however we recommend that
                                    you use smart phones, tablets or computers
                                    that are approximately{' '}
                                    <strong>less than 4 years old</strong>{' '}
                                    <em>
                                        (this is subject to the graphics
                                        hardware of your particular make and
                                        model)
                                    </em>
                                    .
                                </Text>

                                <Text pl="10px" pt="10px">
                                    If you are using a computer with a{' '}
                                    <span style={{ fontWeight: '600' }}>
                                        dedicated graphics processor (GPU)
                                    </span>
                                    , you will get better performance if you
                                    ensure this is used for the web browser
                                    running this web application.
                                </Text>

                                <Text pl="10px" pt="10px">
                                    If applicable, your web browser may support{' '}
                                    <span style={{ fontWeight: '600' }}>
                                        Hardware Acceleration
                                    </span>
                                    . This may also improve performance if this
                                    is turned{' '}
                                    <span style={{ fontWeight: '500' }}>
                                        ON
                                    </span>{' '}
                                    in your browser settings.
                                </Text>

                                <Text pl="10px" pt="10px">
                                    Older devices may still run this web
                                    application, but you are more likely to
                                    experience longer loading times with older
                                    devices and those with limited graphics
                                    performance.
                                </Text>
                                <Text pl="10px" pt="10px">
                                    If using an older device we recommend you
                                    access the briefing function or Met
                                    functions instead of doing a lot of
                                    navigating around the map as these functions
                                    won't require as much performance from your
                                    device.
                                </Text>

                                <Text pl="10px" pt="10px">
                                    Alternatively, you can opt to disable the
                                    map{' '}
                                    <span
                                        style={{
                                            fontWeight: '300',
                                            opacity: '0.8',
                                        }}
                                    >
                                        (details below)
                                    </span>
                                    , or you can use{' '}
                                    <Link
                                        fontWeight="600"
                                        color="light.200"
                                        href="https://ifis.airways.co.nz/"
                                        target="_blank"
                                        rel="noreferrer"
                                    >
                                        IFIS
                                    </Link>{' '}
                                    to access the traditional briefing
                                    information.
                                </Text>

                                <Text pt="25px" fontSize="0.9rem">
                                    <strong>
                                        Recommended actions for improving
                                        performance issues on mobile devices
                                    </strong>
                                </Text>
                                <Text pl="10px" pt="10px">
                                    Older devices or those that have very
                                    limited graphics hardware can potentially
                                    have difficulty running PreFlight smoothly.
                                    This is more common on older mobile devices,
                                    in particular older generation iPads,
                                    Android tablets, iPhones, and cheaper
                                    branded mobile phones.
                                </Text>
                                <Text pl="10px" pt="10px">
                                    Later generation models of these devices
                                    (i.e. typically less than 4 years old)
                                    generally do not experience performance
                                    issues that we are aware of.
                                </Text>

                                <Text pl="10px" pt="10px">
                                    Most performance and technical issues we
                                    have seen that users are experiencing on
                                    their devices have been resolved by{' '}
                                    <strong>
                                        updating the system software to the
                                        latest version
                                    </strong>{' '}
                                    in the device's settings.
                                </Text>
                                <Text pl="10px" pt="10px">
                                    However, there are some additional actions
                                    you can take to help improve the device's
                                    performance:
                                </Text>
                                <Box pl="30px" pt="10px">
                                    <ul>
                                        <li
                                            style={{
                                                paddingBottom: '5px',
                                                paddingLeft: '5px',
                                            }}
                                        >
                                            Closing PreFlight and all other open
                                            apps. Sometimes certain native apps
                                            still running in the background can
                                            influence your device’s performance;
                                        </li>
                                        <li
                                            style={{
                                                paddingBottom: '5px',
                                                paddingLeft: '5px',
                                            }}
                                        >
                                            Clearing your browser cache from
                                            within the device’s settings (some
                                            devices do this through an
                                            Optimization method);
                                        </li>
                                        <li
                                            style={{
                                                paddingBottom: '5px',
                                                paddingLeft: '5px',
                                            }}
                                        >
                                            If necessary, carry out a forced
                                            restart of the mobile device to
                                            clear out any memory blockages.
                                        </li>
                                    </ul>
                                </Box>
                                <Text pl="10px" pt="10px">
                                    You shouldn’t need to do these actions all
                                    the time, it is just to see if anything else
                                    is impacting on your device’s performance.
                                </Text>
                                <Text pl="10px" pt="10px">
                                    Restart PreFlight and see if it is now
                                    running more smoothly.
                                </Text>
                                <Text pl="10px" pt="25px">
                                    The following are some useful links that
                                    provide further information (if applicable):
                                </Text>

                                <Box pl="30px" pt="10px">
                                    <ul>
                                        <li
                                            style={{
                                                paddingBottom: '5px',
                                                paddingLeft: '5px',
                                            }}
                                        >
                                            <Link
                                                fontWeight="400"
                                                color="light.200"
                                                target="_blank"
                                                rel="noreferrer"
                                                href="https://support.apple.com/en-nz/guide/iphone/iph8903c3ee6/ios"
                                            >
                                                How to force restart an iPhone
                                            </Link>
                                        </li>
                                        <li
                                            style={{
                                                paddingBottom: '5px',
                                                paddingLeft: '5px',
                                            }}
                                        >
                                            <Link
                                                fontWeight="400"
                                                color="light.200"
                                                target="_blank"
                                                rel="noreferrer"
                                                href="https://support.apple.com/en-nz/guide/ipad/ipad9955c007/ipados"
                                            >
                                                How to force restart an iPad
                                            </Link>
                                        </li>
                                        <li
                                            style={{
                                                paddingBottom: '5px',
                                                paddingLeft: '5px',
                                            }}
                                        >
                                            <Link
                                                fontWeight="400"
                                                color="light.200"
                                                target="_blank"
                                                rel="noreferrer"
                                                href="https://www.samsung.com/nz/support/mobile-devices/how-to-reset-the-device/"
                                            >
                                                How to soft reset a Samsung
                                                device
                                            </Link>
                                        </li>
                                        <li
                                            style={{
                                                paddingBottom: '5px',
                                                paddingLeft: '5px',
                                            }}
                                        >
                                            <Link
                                                fontWeight="400"
                                                color="light.200"
                                                target="_blank"
                                                rel="noreferrer"
                                                href="https://www.samsung.com/nz/support/mobile-devices/how-to-reboot-a-frozen-galaxy-device/"
                                            >
                                                How to force reboot a Samsung
                                                Galaxy device
                                            </Link>
                                        </li>
                                    </ul>
                                </Box>

                                <Text pt="25px" fontSize="0.9rem">
                                    <strong>Disable Map Option</strong>
                                </Text>

                                <Text pl="10px" pt="10px">
                                    We recognise that the advanced
                                    functionalities of the map may occasionally
                                    place some demands on the performance of
                                    certain devices, particularly older devices
                                    or those with limited graphics capabilities.
                                    While the map generally runs smoothly on
                                    most modern devices, PreFlight offers a{' '}
                                    <span style={{ fontWeight: '600' }}>
                                        Disable Map
                                    </span>{' '}
                                    option for users who may encounter
                                    performance issues, ensuring a more
                                    responsive experience.
                                </Text>
                                <Text pl="10px" pt="10px">
                                    Disabling or enabling the map is easy.
                                    Simply navigate to the{' '}
                                    <span style={{ fontWeight: '700' }}>
                                        Settings
                                    </span>{' '}
                                    tab within your{' '}
                                    <span style={{ fontWeight: '700' }}>
                                        Profile
                                    </span>{' '}
                                    where you'll find the{' '}
                                    <span style={{ fontWeight: '600' }}>
                                        Disable Map
                                    </span>{' '}
                                    toggle switch.
                                </Text>
                                <Text pl="10px" pt="10px">
                                    This option provides the flexibility to
                                    temporarily deactivate the map and related
                                    functions, which may help improve
                                    performance on your device, should you
                                    encounter performance issues. When the map
                                    is disabled, you can still access all
                                    non-map-based features, and a full briefing
                                    from standard briefing areas.
                                </Text>
                                <Text pl="10px" pt="10px" pb="5px">
                                    This feature is optional, and you can
                                    re-enable the map at any time by turning off
                                    this setting.
                                </Text>

                                <Text pt="25px" fontSize="0.9rem">
                                    <strong>PreFlight Support</strong>
                                </Text>

                                <Text pl="10px" pt="10px">
                                    If you are still experiencing issues with
                                    your mobile device, get in touch with us and
                                    we will be happy to assist you further.
                                </Text>
                                <Box pl="30px" pt="10px">
                                    <ul>
                                        <li
                                            style={{
                                                paddingBottom: '5px',
                                                paddingLeft: '5px',
                                            }}
                                        >
                                            Submit a request using the{' '}
                                            <strong>Send us feedback</strong>{' '}
                                            tool within the PreFlight web-app;
                                            or
                                        </li>
                                        <li
                                            style={{
                                                paddingBottom: '5px',
                                                paddingLeft: '5px',
                                            }}
                                        >
                                            Contact:{' '}
                                            <Link
                                                color="light.200"
                                                href="mailto:helpdesk@airwaysinternational.co.nz?subject=Device Performance Issues with PreFlight"
                                            >
                                                helpdesk@airwaysinternational.co.nz
                                            </Link>
                                        </li>
                                    </ul>
                                </Box>
                            </Flex>
                        </ModalBody>
                        <ModalFooter
                            borderTop="1px solid #e5e7e9"
                            pt={4}
                            pb={4}
                        >
                            <Flex
                                width="100%"
                                justifyContent="center"
                                alignItems="center"
                            >
                                <Button
                                    {...noFocus}
                                    w="100%"
                                    size="sm"
                                    maxWidth="180px"
                                    variant="primary"
                                    onClick={onCloseHandler}
                                    bg="light.200"
                                    _hover={{ background: 'light.201' }}
                                    _focus={{ background: 'light.201' }}
                                    _active={{ background: 'light.201' }}
                                    style={{ borderRadius: '10px' }}
                                >
                                    {deviceWarning
                                        ? 'Accept and Continue'
                                        : 'Close'}
                                </Button>
                            </Flex>
                        </ModalFooter>
                    </ModalContent>
                </Modal>
            </LightMode>
        </>
    )
}
